<template>
  <div
    class="masonry-item_type_video"
  >
    <div
      class="masonry-content"
    >
      <video controls width="100%" height="300" :src="item.content.originalUrl" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ItemTypeVideo',
  mounted() {
    this.$emit('resourceLoaded')
  }
}
</script>